<template>
  <div>
    <Teleport to="body">
      <GlobalDialog
        dialog-default-size="50%"
        v-model="showDialog"
        title=""
        :showActionButton="false"
        :force-close="true"
      >
        <template #content>
          <div class="flex items-center justify-between mb-4">
        <h5 class="text-xl font-bold leading-none ">Upcoming Events</h5>
        <a href="#" class="text-sm font-medium text-blue-600 hover:underline blue-500" v-if="false">
            View all
        </a>
   </div>
   <div class="flow-root">
        <ul role="list" class="divide-y divide-gray-200 :divide-gray-700">
            <li v-for="event in events" :key="event.id" class="py-3 sm:py-4">
                <div class="flex items-center">
                    <div class="flex-shrink-0">
                      <button class="flex flex-col items-center justify-center w-36 h-36 rounded font-medium text-white bg-gray-700 border-2 border-white hover:bg-gray-600 dark:border-gray-800" href="#" @click="viewEvent(event.id)">
                        <p class="text-xs">{{formatDate(event.start_at, event.end_at)}}</p>
                        <p class="text-sm">{{formatTime(event.start_at)}}</p>
                      </button>
                    </div>
                    <div class="flex-1 min-w-0 ms-4 pr-4">
                        <h4 class="text-xl font-bold leading-none mb-2">
                          {{event.name}}
                        </h4>
                        <p class="text-xs text-justify">
                          {{event.description ? event.description : 'No description available'}}
                        </p>
                    </div>
                    <button class="inline-flex items-center text-base font-semibold text-sm " @click="viewEvent(event.id)">View Details</button>
                </div>
            </li>
        </ul>
   </div>
        </template>
      </GlobalDialog>
    </Teleport>
  </div>
</template>

<script setup>
import { onMounted, ref, inject } from "vue";
import GlobalDialog from "@/components/layout/GlobalDialog.vue";
import { isEmpty as _isEmpty } from "lodash-es";
import dayjs from "dayjs";
import { useDialogStore } from "@/stores/useDialogStore";
import { useRouter } from "vue-router";


const dialogStore = useDialogStore()
const router = useRouter()

const showDialog = ref(false)
const axios = inject("axios")
const events = ref([]);

const formatDate = (start_date, end_date) => {
  let start = dayjs(start_date).format("MMM D");
  let end = dayjs(end_date).format("MMM D");

  if (start === dayjs().format("MMM D")) {
    return 'Today';
  }

  if (start === end) {
    return start;
  }

  return `${start} - ${end}`;
};

const formatTime = (value) => {
  return dayjs(value).format("h:mm A");
};

const viewEvent = (id) => {
  router.push(`/events?eventId=${id}`)
}
const getEvents = () => {
  axios
    .get("/api/event/incoming-events", {
      params: {
        user_time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    })
    .then(({ data }) => {
      if (_isEmpty(data)) {
        return;
      }

      if (!dialogStore.oneTimeVisible) {
        showDialog.value = true;
        events.value = data;
        dialogStore.isDialogVisible = true
        dialogStore.oneTimeVisible = true;
      }
    });
};
onMounted(async () => {
  getEvents();
});
</script>
