import { ref } from 'vue';

export default function useScorm() {
    const isScormAvailable = ref(false);

    function checkScorm() {
      isScormAvailable.value = false;

      return false;
    }

    return {
        isScormAvailable,
        checkScorm,
    };
}
