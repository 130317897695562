import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";
import { useAuthStore } from "./auth.store";
import { computed } from "vue";
import { useLmsSettingsStore } from "@/stores/useLmsSettingsStore";

export const useEnrolledCourseStore = defineStore("enrolledCourseStore", () => {
  const authStore = useAuthStore();
  const { user } = storeToRefs(authStore);

  const viewedEnrolledCourse = ref([]);
  const label = ref("All Courses");
  const selectedFilter = ref({
    available_course: false,
    sort: null,
    filter: null,
    course_category: [],
    enrolled_course: true,
  });
  const search = ref("");

  const isNewUser = computed(() => user.value.is_new);
  const isManager = computed(
    () =>
      authStore.isSuperAdmin ||
      authStore.isAccountManager ||
      authStore.isDealerManager
  );

  const setViewCourse = (course) => {
    if (
      viewedEnrolledCourse.value.map((course) => course.id).includes(course.id)
    ) {
      return;
    }

    viewedEnrolledCourse.value.push(course);
  };

  const removeCourse = (courseId) => {
    viewedEnrolledCourse.value = viewedEnrolledCourse.value.filter(
      (course) => course.id != courseId
    );
  };

  const initializeFilters = () => {
    const lmsSettingsStore = useLmsSettingsStore();

    if (!lmsSettingsStore.hasEnrollment) {
      return (selectedFilter.value = {
        sort: null,
        filter: null,
        available_course: true,
        enrolled_course: true,
        course_category: [],
      });
    }

    selectedFilter.value = {
      sort: null,
      filter: null,
      available_course: isNewUser.value && !isManager.value,
      enrolled_course: !isNewUser.value || isManager.value,
      course_category: [],
    };
  };

  const handleReset = () => {
    selectedFilter.value = {
      available_course: false,
      sort: null,
      filter: null,
      course_category: [],
      enrolled_course: false,
    };
  };

  return {
    viewedEnrolledCourse,
    label,
    selectedFilter,
    setViewCourse,
    removeCourse,
    handleReset,
    initializeFilters,
    search,
  };
});
