<template>
  <div class="flex flex-col gap-3 rounded-md bg-white shadow-lg w-full">
    <div
      class="flex px-2 gap-2 justify-start items-center sm:px-4 text-lg font-bold text-untitled-gray-600 py-2 border-b border-untitled-gray-200 w-full"
    >
      <p>Featured Video</p>
      <ElButton
        type="primary"
        size="small"
        @click="handleClickChangeVideo"
        v-if="isSuperAdmin || isAccountManager"
        :icon="VideoCameraIcon"
      >
        Change Video
      </ElButton>
    </div>
    <div class="w-full flex flex-col gap-3">
      <DeferredContent @onLoadComponent="handleFetchFeatureVideo">
        <div class="w-full min-h-64">
          <div class="w-full px-4 pb-4" :key="refreshKey">
            <div
              id="youtube-player"
              class="md:flex items-center w-full mt-5 max-h-full min-h-[250px] bg-black justify-center relative"
            >
              <iframe
                v-if="isVimeoVideo"
                id="featured-video"
                width="100%"
                ref="featuredVideoRef"
                height="250"
                :src="
                  !featuredVideoIsUploading && featuredVideo?.url
                    ? featuredVideo?.url
                    : defaultFeaturedVideo
                "
                title="Video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                class="aspect-video"
                @load="handleLoadIframe"
              ></iframe>
              <LoadingWrapper
                v-if="isYoutubeReady || loadingFeaturedVideo || !loadIframe"
              />
            </div>
          </div>
        </div>
      </DeferredContent>
    </div>
  </div>

  <ChangeVideoDialog
    v-if="showChangeVideoDialog"
    :isOpen="showChangeVideoDialog"
    :handleCloseModal="closeModal"
    @refreshVideo="handleRefreshVideo"
  />
</template>

<script setup>
import { ElButton } from "element-plus";
import { VideoCameraIcon } from "@heroicons/vue/solid";
import { useHomeFeaturedVideoStore } from "@/stores/home/useHomeFeaturedVideo.store";
import { storeToRefs } from "pinia";
import LoadingWrapper from "@/components/utilities/LoadingWrapper.vue";
import DeferredContent from "@/components/utilities/DeferredContent.vue";
import Player from "@vimeo/player";
import YouTubePlayer from "youtube-player";
import { ref } from "vue";
import { useAuthStore } from "@/stores";
import { computed } from "vue";
import ChangeVideoDialog from "@/components/home/featured/ChangeVideoDialog.vue";

const authStore = useAuthStore();
const homeFeaturedVideoStore = useHomeFeaturedVideoStore();
const {
  loadingFeaturedVideo,
  featuredVideoIsUploading,
  featuredVideo,
  defaultFeaturedVideo,
} = storeToRefs(homeFeaturedVideoStore);

const isSuperAdmin = computed(() => authStore.isSuperAdmin);
const isAccountManager = computed(() => authStore.isAccountManager);

const isVimeoVideo = ref(false);
const isYoutubeReady = ref(false);
const showChangeVideoDialog = ref(false);
const featuredVideoRef = ref(null);
const loadIframe = ref(false);
const refreshKey = ref(1);

const handleClickChangeVideo = () => {
  showChangeVideoDialog.value = !showChangeVideoDialog.value;
};

const handleFetchFeatureVideo = async () => {
  refreshKey.value++;
  loadIframe.value = false;
  isYoutubeReady.value = false;
  isVimeoVideo.value = false;
  await homeFeaturedVideoStore.handleFetchFeatureVideo();
  setTimeout(() => {
    isVimeo();
  }, 2000);
};

const closeModal = () => {
  showChangeVideoDialog.value = false;
};

const isVimeo = () => {
  const isVimeo = featuredVideo.value.url.includes("vimeo");

  isVimeoVideo.value = isVimeo;

  if (isVimeo) {
    initializeVimeo();
  } else {
    const videoID = featuredVideo.value.url.split("/")[4];
    initializeYoutube(videoID);
  }
};

const handleLoadIframe = () => {
  loadIframe.value = true;
};

const setupVimeoPlayer = () => {
  if (!featuredVideoRef.value) {
    return;
  }

  const iframe = featuredVideoRef.value;
  const player = new Player(iframe);

  player.on("play", function () {
    markFeaturedVideoAsPlayed();
  });

  player.on("ended", function () {
    markFeaturedVideoAsWatched();
  });
}

const initializeVimeo = () => {
  const interval = setInterval(() => {
    if (featuredVideoRef.value) {
      clearInterval(interval);
      setupVimeoPlayer();
    }
  }, 1000);
};

const initializeYoutube = (videoId) => {
  const player = YouTubePlayer("youtube-player", {
    videoId,
    playerVars: { autoplay: 0 },
  });
  player.on("ready", () => {
    isYoutubeReady.value = true;
  });

  player.on("autoplayBlocked");
  player.on("stateChange", (event) => {
    // playing event
    if (event.data === 1) {
      markFeaturedVideoAsPlayed();
      return;
    }
    // end playing
    // ended video
    if (event.data === 0) {
      markFeaturedVideoAsWatched();
    }
    // end ended video
  });
};

const markFeaturedVideoAsPlayed = () => {
  homeFeaturedVideoStore.handlePlayedFeaturedVideo();
};

const markFeaturedVideoAsWatched = () => {
  homeFeaturedVideoStore.handleWatchFeaturedVideo();
};

const handleRefreshVideo = () => {
  handleFetchFeatureVideo();
};
</script>

<style lang="scss" scoped></style>
