<template>
  <div class="w-full h-fit pb-10 lg:sticky top-20 flex flex-col gap-3">
    <Layout2RightSectionTabs />
    <KeepAlive>
      <component :is="selectedComponent" />
    </KeepAlive>
    <div class="w-full border-t border-untitled-gray-300" />
    <FeaturedVideo />
    <div class="w-full border-t border-untitled-gray-300" />
    <div class="bg-white">
      <GroupListSide></GroupListSide>
    </div>
  </div>
</template>

<script setup>
import FeaturedVideo from "@/components/new-home/featured-video/FeaturedVideo.vue";
import Layout2RightSectionTabs from "./right-section/Layout2RightSectionTabs.vue";
import GroupListSide from "@/components/groups/GroupListSide.vue";
import { computed, defineAsyncComponent } from "vue";
import { useLayout2HomeTabsStore } from "../stores/useLayout2HomeTabs.store";
import { storeToRefs } from "pinia";

const Layout2ReportSummary = defineAsyncComponent(() =>
  import("./right-section/Layout2ReportSummary.vue")
);
const Layout2MyFavorites = defineAsyncComponent(() =>
  import("./right-section/Layout2MyFavorites.vue")
);
const Layout2EventsAndReminders = defineAsyncComponent(() =>
  import("./right-section/Layout2EventsAndReminders.vue")
);

const homeTabStore = useLayout2HomeTabsStore();
const { selectedRightSectionTab } = storeToRefs(homeTabStore);

const selectedComponent = computed(() => {
  if (selectedRightSectionTab.value === "report_summary") {
    return Layout2ReportSummary;
  }
  if (selectedRightSectionTab.value === "my_favorites") {
    return Layout2MyFavorites;
  }
  return Layout2EventsAndReminders;
});
</script>

<style lang="scss" scoped></style>
