<template>
  <div
    class="flex flex-col sm:flex-wrap md:flex-row gap-3 bg-opacity-90 bg-untitled-gray-50"
  >
    <div
      class="flex justify-center items-center bg-white p-2 border border-untitled-gray-200 md:shadow-lg rounded-md cursor-pointer drop-shadow-sm gap-2"
      @click="handleClickTab('featured')"
      v-if="selectedMainTab !== 'featured'"
    >
      <el-tooltip
        :content="wrapTooltipContent('Show All Featured Contents')"
        effect="dark"
        :raw-content="true"
        placement="bottom"
      >
        <HomeIcon class="w-7 text-untitled-gray-600 hidden md:block" />
      </el-tooltip>
      <p
        class="text-xs text-untitled-gray-600 font-semibold uppercase text-center md:hidden"
      >
        Featured Content
      </p>
    </div>
    <div
      class="p-2 sm:p-3 rounded-md shadow flex-1 flex justify-center items-center cursor-pointer hover:bg-untitled-gray-100"
      @click="handleClickTab('enrolled')"
      :class="[
        {
          'bg-untitled-gray-200': selectedMainTab === 'enrolled',
          'bg-white': selectedMainTab !== 'enrolled',
        },
      ]"
    >
      <p
        class="text-xs text-untitled-gray-600 font-semibold uppercase text-center"
      >
        {{ tabName }}
      </p>
    </div>
    <div
      class="p-2 sm:p-3 rounded-md shadow flex-1 flex justify-center items-center cursor-pointer hover:bg-untitled-gray-100"
      @click="handleClickTab('playlist')"
      :class="[
        {
          'bg-untitled-gray-200': selectedMainTab === 'playlist',
          'bg-white': selectedMainTab !== 'playlist',
        },
      ]"
    >
      <p
        class="text-xs text-untitled-gray-600 font-semibold uppercase text-center"
      >
        {{ userName }} Learning Path & Playlists
      </p>
    </div>
    <div
      class="p-2 sm:p-3 rounded-md shadow flex-1 flex justify-center items-center cursor-pointer hover:bg-untitled-gray-100"
      @click="handleClickTab('content')"
      :class="[
        {
          'bg-untitled-gray-200': selectedMainTab === 'content',
          'bg-white': selectedMainTab !== 'content',
        },
      ]"
    >
      <p
        class="text-xs text-untitled-gray-600 font-semibold uppercase text-center"
      >
        All Available Content
      </p>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useLayout2HomeTabsStore } from "../stores/useLayout2HomeTabs.store";
import { ElTooltip } from "element-plus";
import { useAuthStore } from "@/stores";
import { computed } from "vue";
import { HomeIcon } from "@heroicons/vue/solid";
import { wrapTooltipContent } from "@/composable/wrapTooltipContent";
import { useLayout2CoursesStore } from "../stores/useLayout2Courses.store";

const authStore = useAuthStore();
const layout2HomeTabsStore = useLayout2HomeTabsStore();
const homeCoursesStore = useLayout2CoursesStore();
const { selectedMainTab } = storeToRefs(layout2HomeTabsStore);

const userName = computed(() => authStore.fullName);
const tabName = computed(() => homeCoursesStore.homeTab);

const handleClickTab = (tab) => {
  selectedMainTab.value = tab;
};
</script>

<style scoped></style>
