import { useLmsSettingsStore } from "@/stores/useLmsSettingsStore";

export default {
  install: (app) => {
    app.directive("has-enrollment", {
      mounted: (el, binding) => {
        if (!binding.value && !useLmsSettingsStore().hasEnrollment) {
          el.style.display = "none";
        } else {
          el.style.visibility = "visible";
        }
      },
      updated: (el, binding) => {
        if (!binding.value && !useLmsSettingsStore().hasEnrollment) {
          el.style.display = "none";
        } else {
          el.style.visibility = "visible";
        }
      },
    });

    app.directive("has-require-arp", {
      mounted: (el, binding) => {
        if (binding.value && !useLmsSettingsStore().hasRequireArp) {
          el.style.display = "none";
        } else {
          el.style.visibility = "visible";
        }
      },
      updated: (el, binding) => {
        if (binding.value && !useLmsSettingsStore().hasRequireArp) {
          el.style.display = "none";
        } else {
          el.style.visibility = "visible";
        }
      },
    });

    app.directive("has-restrict-learner", {
      mounted: (el, binding) => {
        if (!binding.value && !useLmsSettingsStore().isRestrictLearner) {
          el.style.display = "none";
        } else {
          el.style.visibility = "visible";
        }
      },
      updated: (el, binding) => {
        if (!binding.value && !useLmsSettingsStore().isRestrictLearner) {
          el.style.display = "none";
        } else {
          el.style.visibility = "visible";
        }
      },
    });

    app.directive("has-hide-arp", {
      mounted: (el, binding) => {
        if (!binding.value && useLmsSettingsStore().isHideArp) {
          el.style.display = "none";
        } else {
          el.style.visibility = "visible";
        }
      },
      updated: (el, binding) => {
        if (!binding.value && useLmsSettingsStore().isHideArp) {
          el.style.display = "none";
        } else {
          el.style.visibility = "visible";
        }
      },
    });
  },
};
