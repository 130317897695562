import { http } from "@/composable/httpService";
import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";
import { computed } from "vue";
import { useLmsSettingsStore } from "@/stores/useLmsSettingsStore";
import { useEnrolledCourseStore } from "@/stores/enrolledCourseStore";

export const useLayout2CoursesStore = defineStore("layout2CoursesStore", () => {
  const lmsSettingStore = useLmsSettingsStore();
  const enrolledCourseStore = useEnrolledCourseStore();
  const { selectedFilter } = storeToRefs(enrolledCourseStore);

  const courses = ref([]);
  const loadingCourses = ref(false);
  const search = ref("");
  const originalCourses = ref([]);

  const hasEnrollment = computed(() => lmsSettingStore.hasEnrollment);

  const homeTab = computed(() => {
    if (hasEnrollment.value && !selectedFilter.value) {
      return "Enrolled Courses";
    }
    if (
      hasEnrollment.value &&
      selectedFilter.value &&
      selectedFilter.value.available_course &&
      !selectedFilter.value.enrolled_course
    ) {
      return "Available Courses";
    }

    if (
      hasEnrollment.value &&
      selectedFilter.value &&
      selectedFilter.value.enrolled_course &&
      !selectedFilter.value.available_course
    ) {
      return "Enrolled Courses";
    }

    return "All Courses";
  });

  const filteredCourses = computed(() => {
    let newCourses = courses.value;
    // filtering
    if (selectedFilter.value && selectedFilter.value.filter) {
      const filter = selectedFilter.value.filter;
      switch (filter) {
        case "not started":
          newCourses = courses.value.filter((course) => {
            return course.completed_percentage == 0;
          });
          break;
        case "in progress":
          newCourses = courses.value.filter((course) => {
            return (
              course.completed_percentage > 0 &&
              course.completed_percentage < 100
            );
          });
          break;
        case "completed":
          newCourses = courses.value.filter((course) => {
            return course.completed_percentage == 100;
          });
          break;
        case "all":
          newCourses = courses.value;
          break;
      }
    }

    // sorting
    if (selectedFilter.value && selectedFilter.value.sort) {
      const sort = selectedFilter.value.sort;
      switch (sort) {
        case "a-z":
          newCourses = newCourses.sort((a, b) =>
            a.name?.toLowerCase() > b.name?.toLowerCase()
              ? 1
              : b.name?.toLowerCase() > a.name?.toLowerCase()
              ? -1
              : 0
          );
          break;
        case "z-a":
          newCourses = newCourses.sort((a, b) =>
            a.name?.toLowerCase() < b.name?.toLowerCase()
              ? 1
              : b.name?.toLowerCase() < a.name?.toLowerCase()
              ? -1
              : 0
          );
          break;
        case "lth":
          newCourses = newCourses.sort((a, b) =>
            a.completed_percentage > b.completed_percentage
              ? 1
              : b.completed_percentage > a.completed_percentage
              ? -1
              : 0
          );
          break;
        case "htl":
          newCourses = newCourses.sort((a, b) =>
            a.completed_percentage < b.completed_percentage
              ? 1
              : b.completed_percentage < a.completed_percentage
              ? -1
              : 0
          );
          break;
        case "default":
          newCourses = newCourses.sort((a, b) =>
            a.id > b.id ? 1 : b.id > a.id ? -1 : 0
          );
          break;
      }
    }

    return newCourses;
  });

  const handleFetchCourses = async () => {
    try {
      loadingCourses.value = true;
      const { data } = await http().post("/v3/courses/list", {
        keyword: search.value,
        ...selectedFilter.value,
      });
      courses.value = data;
      if (
        hasEnrollment.value &&
        courses.value.length === 0 &&
        selectedFilter.value.enrolled_course &&
        !selectedFilter.value.available_course
      ) {
        //
        selectedFilter.value = {
          available_course: true,
          enrolled_course: false,
          course_category: selectedFilter.value.course_category,
          filter: selectedFilter.value.filter,
          sort: selectedFilter.value.sort,
        };
        handleFetchCourses();
        return;
      }
    } catch (error) {
      useHandleErrorStatus(error);
    } finally {
      loadingCourses.value = false;
    }
  };

  return {
    courses,
    handleFetchCourses,
    homeTab,
    originalCourses,
    filteredCourses,
    loadingCourses,
  };
});
