import { http } from "@/composable/httpService";
import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import { defineStore } from "pinia";
import { computed } from "vue";
import { ref } from "vue";

export const useHomeFeaturedVideoStore = defineStore(
  "homeFeaturedVideoStore",
  () => {
    const loadingFeaturedVideo = ref(false);
    const featuredVideo = ref();
    const featuredVideoIsUploading = ref(false);
    const defaultFeaturedVideo = ref("");

    const featuredVideoParams = computed(() => {
      return {
        featuredVideoID: featuredVideo.value.id,
      };
    });

    const handleFetchFeatureVideo = async () => {
      try {
        loadingFeaturedVideo.value = true;
        const { data } = await http().get("/get-featured-video");
        featuredVideo.value = data.featured_video;
        featuredVideoIsUploading.value = data.uploading;
        defaultFeaturedVideo.value = data.default_video;
      } catch (error) {
        useHandleErrorStatus(error);
      } finally {
        loadingFeaturedVideo.value = false;
      }
    };

    const handlePlayedFeaturedVideo = async () => {
      try {
        await http().post("/played-featured-video", featuredVideoParams.value);
      } catch (error) {
        useHandleErrorStatus(error);
      }
    };

    const handleWatchFeaturedVideo = async () => {
      try {
        await http().post("/watched-featured-video", featuredVideoParams.value);
      } catch (error) {
        useHandleErrorStatus(error);
      }
    };

    return {
      loadingFeaturedVideo,
      featuredVideo,
      featuredVideoIsUploading,
      defaultFeaturedVideo,
      handleFetchFeatureVideo,
      handlePlayedFeaturedVideo,
      handleWatchFeaturedVideo,
    };
  }
);
