import { defineStore } from "pinia";
import { computed } from "vue";
import { ref } from "vue";

export const useEventBannerStore = defineStore("eventBannerStore", () => {
  const event = ref(null);

  const hasBanner = computed(() =>
    event.value && event.value.id ? true : false
  );

  return {
    event,
    hasBanner,
  };
});
