<script setup>
import { PlusIcon, EyeIcon } from "@heroicons/vue/solid";

import CreateEventDialog from "./CreateEventDialog";
import eventBus from "@/plugins/eventBus";
import dayjs from "dayjs";
import { onMounted, ref, computed, watch, onUnmounted } from "vue";
import { fetchUpcomingEvents } from "@/composable/event/get-upcoming-events";
import GlobalListSkeleton from "@/components/layout/GlobalListSkeleton.vue";
import { useRouter } from "vue-router";

const router = useRouter();

const isOpen = ref(false);
const params = computed(() => {
  return {
    userTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    isFromHomePage: true,
  };
});
const {
  load: loadEvents,
  data: events,
  loading,
} = fetchUpcomingEvents(params.value);

const openRegisterLink = (event) => {
  router.push({
    name: "event-detail-page",
    params: { id: event.id },
  });
};

const openEventLink = (event) => {
  window.open(event.url, "_blank");
};

const formatDate = (start_at) => {
  return dayjs(start_at).format("ddd, MMM D, YYYY h:mm A");
};

const showNotification = (message = "Saved successfully") => {
  eventBus.$emit("CREATE_NOTIFICATION", message, "success", 6500);
};

onMounted(async () => {
  await loadEvents(params.value);

  eventBus.$on("NEW_EVENT", () => {
    showNotification();
    loadEvents(params.value);
  });
});

onUnmounted(() => {
  eventBus.$off("NEW_EVENT");
});

watch(params, async () => {
  await loadEvents(params.value);
});
</script>
<template>
  <div>
    <div class="-mt-2 flex justify-between flex-wrap sm:flex-nowrap">
      <div class="ml-4 mt-4">
        <h3 class="text-untitled-gray-800 font-bold inline">Upcoming Events</h3>
      </div>
      <div
        class="mr-2 mt-2 flex-shrink-0 w-full xl:w-auto lg:w-auto md:w-auto flex justify-end"
      >
      <div class="mt-4 flex justify-center">
          <router-link
                    :to="{ name: 'create-event-page' }"
                    :class="[
                      active
                        ? 'bg-untitled-gray-100 text-untitled-gray-900'
                        : 'text-untitled-gray-700',
                      'group flex items-center px-4 py-2 text-sm inline-flex items-center px-4 py-2 border border-untitled-gray-300 shadow-sm text-sm font-medium rounded-md text-untitled-gray-700 bg-white hover:bg-untitled-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500',
                    ]"
                  >
                    <PlusIcon
                      class="mr-3 h-5 w-5 text-untitled-gray-400 group-hover:text-untitled-gray-500"
                      aria-hidden="true"
                    />
                    Create Event
                  </router-link>
        </div>
      </div>
    </div>
    <div class="flex items-center justify-center">
      <div class="md:py-4 py-5 w-full">
        <div class="px-4">
          <template v-if="!events.length && !loading">
            <div class="flex justify-center items-center content-center h-56">
              <h3>No upcoming events</h3>
            </div>
          </template>
          <template v-if="events && events.length && !loading">
            <div
              v-for="event in events"
              :key="event.id"
              class="border-b pb-4 border-untitled-gray-400 border-dashed"
            >
              <div class="flex justify-between items-center">
                <p
                  class="text-xs font-light leading-3 text-untitled-gray-500 mt-5"
                >
                  {{ formatDate(event.start_at_formatted) }}
                </p>
                <p v-if="event.is_draft" class="text-xs font-bold leading-3 text-untitled-gray-50 mt-5 bg-untitled-gray-500 px-5 py-2 rounded-2xl">Draft</p>
              </div>
              <a
                tabindex="0"
                class="focus:outline-none text-lg font-medium leading-5 text-untitled-gray-800 mt-2"
                >{{ event.name }}</a
              >
              <p
                class="text-sm pt-2 leading-4 leading-none text-untitled-gray-600"
              >
                {{ event.description }}
              </p>
              <div v-if="event.is_draft === 0">
                <button
                  v-if="event.has_access_to_join"
                  @click="openEventLink(event)"
                  class="mt-5 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-800"
                >
                  Join Training Session
                </button>
                <button
                  v-else
                  @click="openRegisterLink(event)"
                  class="mt-5 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-800"
                >
                  Register now
                </button>
              </div>
              <div v-else>

                <button
                  disabled
                  class="mt-5 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-untitled-gray-800 bg-untitled-gray-200 hover:bg-untitled-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-800"
                >
                  Register Soon
                </button>
              </div>
            </div>
          </template>
          <div v-if="loading">
            <GlobalListSkeleton :display="3" :show-avatar="false" />
          </div>
        </div>
        <div class="mt-4 flex justify-center">
          <router-link
            to="/events"
            class="inline-flex items-center px-4 py-2 border border-untitled-gray-300 shadow-sm text-sm font-medium rounded-md text-untitled-gray-700 bg-white hover:bg-untitled-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-untitled-gray-500"
          >
            <EyeIcon class="mr-2 h-5 w-5 text-untitled-gray-400 group-hover:text-untitled-gray-500" aria-hidden="true" />
            View All Events
          </router-link>
        </div>
      </div>
    </div>
  </div>

  <create-event-dialog v-model="isOpen" :isFromHomePage="true" />
</template>
