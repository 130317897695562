<template>
  <div class="flex flex-row gap-3">
    <div
      @click="handleClickTab('report_summary')"
      :class="[
        'p-2  rounded shadow flex-1 flex justify-center items-center cursor-pointer hover:bg-untitled-gray-50',
        {
          'bg-white': selectedRightSectionTab !== 'report_summary',
          'bg-untitled-gray-200 ': selectedRightSectionTab === 'report_summary',
        },
      ]"
    >
      <p
        class="text-xs text-untitled-gray-600 font-medium uppercase text-center"
      >
        Report Summary
      </p>
    </div>
    <div
      v-if="false"
      @click="handleClickTab('my_favorites')"
      :class="[
        'p-2  rounded shadow flex-1 flex justify-center items-center cursor-pointer hover:bg-untitled-gray-50',
        {
          'bg-white': selectedRightSectionTab !== 'my_favorites',
          'bg-untitled-gray-200 ': selectedRightSectionTab === 'my_favorites',
        },
      ]"
    >
      <p
        class="text-xs text-untitled-gray-600 font-medium uppercase text-center"
      >
        My Favorites
      </p>
    </div>
    <div
      @click="handleClickTab('events_and_reminders')"
      :class="[
        'p-2  rounded shadow flex-1 flex justify-center items-center cursor-pointer hover:bg-untitled-gray-50',
        {
          'bg-white': selectedRightSectionTab !== 'events_and_reminders',
          'bg-untitled-gray-200 ':
            selectedRightSectionTab === 'events_and_reminders',
        },
      ]"
    >
      <p
        class="text-xs text-untitled-gray-600 font-medium uppercase text-center"
      >
        Events & Reminders
      </p>
    </div>
  </div>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useLayout2HomeTabsStore } from "../../stores/useLayout2HomeTabs.store";

const homeTabStore = useLayout2HomeTabsStore();
const { selectedRightSectionTab } = storeToRefs(homeTabStore);

const handleClickTab = (tab) => {
  selectedRightSectionTab.value = tab;
};
</script>

<style lang="scss" scoped></style>
