<template>
  <div
    class="absolute top-0 right-0 w-full bg-opacity-85 border flex justify-center items-center z-10 h-full backdrop-blur-sm bg-untitled-gray-200 border-untitled-gray-200 flex-col gap-5"
    v-bind="$attrs"
  >
    <LoadingSpinner />
    <p class="text-xs font-semibold text-untitled-gray-600" v-if="text">
      {{ text }}
    </p>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import LoadingSpinner from "./LoadingSpinner.vue";
import { computed } from "vue";
const props = defineProps({
  text: {
    type: String,
  },
});

const text = computed(() => props.text);
</script>

<style scoped></style>
