import { defineStore } from "pinia";
import { useAuthStore } from "./auth.store";
import { useHandleErrorStatus } from "@/composable/useHandleErrorStatus";
import { http } from "@/composable/httpService";

export const useLmsSettingsStore = defineStore("LmsSettingsStore", {
  state: () => ({
    settings: [],
    loadingSettings: false,
  }),
  getters: {
    authUser() {
      return useAuthStore().user;
    },
    hasEnrollment() {
      if (this.authUser?.dealer?.lms_settings?.length) {
        return this.authUser.dealer.lms_settings.some((setting) => {
          return setting.system_name === "course_enrollment";
        });
      }

      return false;
    },
    hasRequireArp() {
      if (this.authUser?.dealer?.lms_settings?.length) {
        return this.authUser.dealer.lms_settings.some((setting) => {
          return setting.system_name === "require_arp";
        });
      }

      return false;
    },
    hasRequireAssignStartAndEndDate() {
      if (
        this.authUser &&
        this.authUser.dealer &&
        this.authUser.dealer.lms_settings &&
        this.authUser.dealer.lms_settings.length
      ) {
        return this.authUser.dealer.lms_settings.some((setting) => {
          return setting.system_name === "require_assign_start_and_end_date";
        });
      }

      return false;
    },
    isRestrictQuiz() {
      if (this.authUser?.dealer?.lms_settings?.length) {
        return this.authUser.dealer.lms_settings.some((setting) => {
          return setting.system_name === "restrict_quiz";
        });
      }

      return false;
    },
    isRestrictLearner() {
      if (
        this.authUser?.dealer?.lms_settings?.length &&
        !useAuthStore().isSuperAdmin &&
        !useAuthStore().isDealerManager &&
        !useAuthStore().isAccountManager &&
        useAuthStore().isLearner
      ) {
        return !this.authUser.dealer.lms_settings.some((setting) => {
          return setting.system_name === "restrict_learner";
        });
      }
      return true;
    },
    isHideArp() {
      if (this.authUser?.dealer?.lms_settings?.length) {
        return this.authUser.dealer.lms_settings.some((setting) => {
          console.log(setting.system_name);
          return setting.system_name === "disabled_arp";
        });
      }
      return false;
    },
  },
  actions: {
    async fetchLmsSettings() {
      try {
        this.loadingSettings = true;
        const response = await http().get("/v3/lms-settings/list");
        this.settings = response.data;
      } catch (error) {
        useHandleErrorStatus(error);
      } finally {
        this.loadingSettings = false;
      }
    },
    async checkSettingIfAction(settingId) {
      let response = null;
      try {
        const res = await http().get(`/v3/lms-settings/check/${settingId}`);
        response = res.data;
      } catch (error) {
        useHandleErrorStatus(error);
      }

      return response;
    },
    async storeSetting(settingId) {
      try {
        await http().post(`/v3/lms-settings/store/${settingId}`);
      } catch (error) {
        useHandleErrorStatus(error);
      }
    },
    async handleTurnOffSetting(settingId) {
      try {
        await http().post(`/v3/lms-settings/remove/${settingId}`);
      } catch (error) {
        useHandleErrorStatus(error);
      }
    },
  },
});
